@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Plus Jakarta Sans' !important;
}
body {
  background: #edf6f8 !important;
}
div,
p,
span,
th,
td {
  @apply font-sans;
}
@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../public/fonts/PlusJakartaSans-Medium.ttf');
}
@font-face {
  font-family: 'OCR A Extended';
  src: url('../public/fonts/OCRAEXT.TTF');
}
.dialogBackdropBackground {
  backdrop-filter: blur(2px);
}
.divider {
  @apply w-full h-[1px] bg-gray-lighter_5;
}
.spacing-4 {
  @apply mb-4;
}
.spacing-8 {
  @apply mb-8;
}

mat-icon {
  @apply cursor-pointer;
  &:hover {
    @apply text-black-primary;
  }
}
.progress-wrapper {
  @apply flex items-center justify-center p-xxl w-full;
}
.not-available {
  @apply w-fit rounded-full flex font-medium text-red-primary px-md py-xs items-center justify-center bg-red-secondary;
}

// mat-select overrides
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  @apply text-blue-primary #{!important};
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #00bfff !important;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #00bfff !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
  @apply text-blue-primary #{!important};
}
////////////////////////
